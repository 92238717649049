import React from 'react';
import { navigate } from 'gatsby';
import MainHeader from '../components/main-header/main-header';
import PageHeader from '../components/page-header/page-header';
import Button from '../components/button/button';
import './index.scss';
import MainFooter from '../components/main-footer/main-footer';

const block = 'page-error';
const Page = () => {
  return (
    <main className={`container--mobile ${block}`}>
      <MainHeader />
      <PageHeader
        title="Ops, não encotramos nada"
        description={
          <span>
            Volte a página inicial e comece novamente.
            <br />
          </span>
        }
        breadCrumbs={[]}
        hide
      />
      <div className="container">
        <Button
          theme={'secondary'}
          className="button_cta"
          onClick={() => {
            navigate('/');
          }}
        >
          Ir para página inicial
        </Button>
      </div>
      <MainFooter />
    </main>
  );
};

export default Page;
